<template>
  <div class="footer">
    <div class="wrapper-footer">
      <div class="contatos">
        <h3>Contatos</h3>
        <ul>
          <li><a href="https://api.whatsapp.com/send?phone=551145700404&text="><v-icon>mdi-whatsapp</v-icon>(11) 4570-0404</a></li>
          <li><a href="mailto:contato@aluguelblindado.com.br"><v-icon>mdi-email-outline</v-icon>contato@aluguelblindado.com.br</a></li>
        </ul>
      </div>
      <div class="linkutes">
        <h3>Links Rápidos</h3>
        <div class="linkutes-row">
          <div class="col3-links">
          <ul>
            <li><a href="https://aluguelblindado.com.br">Análise Gratuita</a></li>
            <li><a href="https://aluguelblindado.com.br/#sobre">Sobre</a></li>
            <li><a href="https://aluguelblindado.com.br/#servicos">Serviços</a></li>
          </ul>
          </div>
          <div class="col3-links">
            <ul>
              <li><a href="https://aluguelblindado.com.br/modelos">Modelos de Documentos</a></li>
              <li><a href="http://porto.vc/CARTAOPORTOF133710J">Solicitar Cartão Porto</a></li>
              <li><a href="https://aluguelblindado.com.br/#faq">Perguntas e Respostas</a></li>
            </ul>
          </div>
          <div class="col3-links">
            <ul>
              <li><a href="https://aluguelblindado.com.br/blog/">Blog</a></li>
              <li><a href="https://aluguelblindado.com.br/#depoimentos">Depoimentos</a></li>
              <li><a href="https://aluguelblindado.com.br/politica-de-privacidade/">Política de Privacidade</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-footer">
      <div class="logofooter">
        <img src="../assets/aluguel-blindado-logo-white.png" alt="Logo Alguel Blindado">
      </div>
      <div class="txtfooter">
        <p class="textfooter">© 2022 Aluguel Blindado. Todos os Direitos Reservados | <a href="https://marjo.com.br" target="_blank">Desenvolvido por Marjô</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer{
        width: 100%;
        background-color: #1FAAE3;
        padding: 16px 0;
    }

    .textfooter{
        color: #fff;
        font-size: 14px;
        font-weight: normal;
    }

    .textfooter a{
      color: #fff;
      text-decoration: none;
    }

    .textfooter a:hover{
      color: #000;
    }

    .wrapper-footer{
      max-width: 980px;
      display: flex;
      justify-content: center;
      margin: 8px auto;
    }

    .logofooter{
      width: 30%;
    }

    .logofooter img{
      width: 250px;
      margin: 0 auto;
    }

    .txtfooter{
      width: 70%;
      text-align: end;
      margin: auto 0;
    }

    .contatos{
      width: 35%;
    }

    .linkutes{
      width: 65%;
    }

    .contatos h3,
    .linkutes h3{
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;
    }

    .linkutes-row{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .col3-links{
      width: 33%;
    }

    ul{
      list-style: none;
      margin: 16px 0;
    }

    ul li{
      margin: 8px 0;
    }

    ul li a{
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      text-decoration: none;
    }

    ul li a:hover{
      color: #000;
    }

    ul li a i{
      color: #fff !important;
      margin-right: 8px;
    }

    @media (max-width: 420px){
      .contatos,
      .linkutes,
      .logofooter,
      .txtfooter,
      .col3-links{
        width: 100%;
        padding: 0 16px;
      }

      .wrapper-footer,
      .linkutes-row{
        display: block;
      }

      .logofooter{text-align: center;}

      .logofooter img{
        width: 50%;
      }

      .txtfooter{
        text-align: center;
      }

      .contatos h3,
      .linkutes h3,
      ul li{
        text-align: center;
      }
    }

</style>