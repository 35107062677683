<template>
<div>
    <div class="navbar">
        <div class="img-div">
            <img src="../assets/logo-aluguel-blindado.png" alt="Logo Alguel Blindado">
        </div>
    </div>
    <div class="containerForm" v-if="acesso == '0'">
        <v-container>
            <v-row>
                <v-col cols="12" md="12">
                    <v-card class="vcard-main">
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-file-plus</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="openorcamento"
                                class="btn-cancel-action"
                                >
                                Novo Orçamento
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card  class="vcard-main">
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-file-search</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="openpesquisaorcamento"
                                class="btn-cancel-action2"
                                >
                                Pesquisar Orçamento
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="vcard-main">
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-folder-search</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="openpesquisaapolice"
                                class="btn-cancel-action2"
                                >
                                Pesquisar Apolice
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        
    </div>
  <div class="containerForm" v-if="acesso == '1'">
    <h2 class="title-form">Faça Agora a Análise Cadastral</h2>
    <p class="subtitle-form">Preencha os dados abaixo para solicitar a análise.</p>
    <div class="form-wrapper">
        <FormulateForm
            name="form-locatario"
            @submit="add"
        >
            <p class="title-info-p"><span class="title-info">Adicionar locatários</span><br>
                <span class="subtitle-info">OBS: Você pode adicionar até 10 locatários para a análise.</span></p>
            
            <hr>
                <FormulateInput
                    type="text"
                    name="nomeloct"
                    v-model="nomeorcamento"
                    label="Nome Completo"
                    placeholder="Digite o nome completo"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo Obrigatório'
                    }"
                />
                <FormulateInput
                    type="text"
                    name="cpf"
                    v-model="cpforcamento"
                    label="CPF"
                    v-mask="maskcpf"
                    placeholder="Digite o CPF (Somente os números)"
                    validation="bail|required|min:14,length"
                    :validation-messages="{
                        required: 'Campo obrigatório',
                        min: 'CPF contém no minimo 11 digitos'
                    }"
                />
                <FormulateInput
                    type="text"
                    name="dtnasclct"
                    v-mask="maskdate"
                    v-model="dtnasorcamento"
                    placeholder="dd/mm/aaaa"
                    label="Data de Nascimento"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="email"
                    name="email"
                    v-model="emailorcamento"
                    label="E-mail"
                    placeholder="Digite o e-mail"
                    validation="bail|required|email"
                    :validation-messages="{
                        required: 'Campo obrigatório',
                        email: 'Digite um e-mail valido'
                    }"
                />
            <FormulateInput
                type="text"
                name="celular"
                v-model="celularorcamento"
                label="Celular"
                v-mask="maskcelular"
                placeholder="Digite seu número de Celular"
                validation="bail|required|min:15,length"
                :validation-messages="{
                    required: 'Campo obrigatório',
                    min: 'Celular deve conter 11 digitos com DDD'
                }"
            />
            <div v-if="valert1" id="alerta-erro-1">
                <v-alert class="alert-erro-1" border="bottom" type="error"><span>{{ msgerroalerta }}</span></v-alert>
            </div>
           
            <FormulateInput
                type="submit"
                label="Adicionar Locatário"
                class="btn-action"
            />

            
            
            <div class="table-cobertura" v-if="tabelalocatarios == true">
                <table>
                    <tr v-for="l in locatarios" :key="l.cpf">
                        <td>{{ l.nome }}</td>
                        <td>{{ l.cpf }}</td>
                        <td><button class="btn-delete" @click.prevent="deletelocatario(l.cpf)"><v-icon small>mdi-trash-can-outline</v-icon></button></td>
                    </tr>
                </table>
            </div>
        </FormulateForm>
        <FormulateForm  @submit="submitHandler">
            <p class="title-info-p"><span class="title-info">Informações do Imóvel</span></p>
            <hr>
                <FormulateInput
                    type="text"
                    name="ceporcamento"
                    label="CEP"
                    v-mask="maskcep"
                    v-model="ceporcamento"
                    placeholder="CEP do imóvel desejado"
                    validation="bail|required|min:9,length"
                    :validation-messages="{
                        required: 'Campo obrigatório',
                        min: 'CEP deve conter somente 8 digitos'
                    }"
                />
                <FormulateInput
                    type="text"
                    name="valorIS"
                    v-model="valor"
                    label="Valor do Aluguel"
                    v-mask="currencyMask"
                    placeholder="Digite o valor"
                    validation="bail|required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
            <FormulateInput
                    type="select"
                    name="mesescontrato"
                    v-model="mesescontrato"
                    label="Periodo de Contrato"
                    placeholder="Selecione"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo Obrigatório'
                    }"
                    :options="[
                        {value: '06', label: '6 Meses'},
                        {value: '12', label: '12 Meses'},
                        {value: '18', label: '18 Meses'},
                        {value: '24', label: '24 Meses'},
                        {value: '30', label: '30 Meses'},
                        {value: '36', label: '36 Meses'},
                        {value: '42', label: '42 Meses'},
                        {value: '48', label: '48 Meses'},
                        {value: '54', label: '54 Meses'},
                        {value: '60', label: '60 Meses'}
                    ]"
                />
                <div v-if="valert2" id="alerta-erro-2"><v-alert class="alert-erro-2" border="bottom" type="error"><span>{{ msgerroalerta }}</span></v-alert></div>
                
            <FormulateInput v-if="this.locatarios.length !== 0 "
                type="submit"
                label="SOLICITAR ANÁLISE"
                class="btn-finalizar-action"
            />
        </FormulateForm>
        <!-- <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            VOLTAR
        </v-btn> -->
    </div>
    <div v-if="dots" class="dots"></div>
  </div>
  <div v-else-if="acesso == '2'" class="containerForm">
        <span><b>Número Orçamento: </b>{{ dadosanalise.orcamento }}</span><br>
        <span><b>Periodo: </b>{{ dadosanalise.contrato.inicioSeguro }} - {{ dadosanalise.contrato.fimSeguro }}</span> 
        <v-icon small style="margin:0px 8px" @click.prevent="formDataAtualizar">
            mdi-pencil
        </v-icon>
        <!-- <div v-if="dots2" class="dots2"></div>
        <span id="pdforcamento"></span> -->
        <p class="title-info-p"><span class="title-info">Informações do Imovel</span></p> <hr>

        <p><span><b>CEP: </b>{{ dadosanalise.localRisco.cep }}</span></p>
        <p><span><b>Endereço: </b>{{ dadosanalise.localRisco.tipoLogradouro }} {{ dadosanalise.localRisco.logradouro }}, {{ dadosanalise.localRisco.numero }}<b> Bairro: </b>{{ dadosanalise.localRisco.bairro }}</span></p>
        <p><span><b>Complemento: </b>{{ dadosanalise.localRisco.complemento }}</span></p>
        <p><span><b>Cidade: </b>{{ dadosanalise.localRisco.municipio }}<b> Estado: </b>{{ dadosanalise.localRisco.estado }}</span></p>

        <p class="title-info-p"><span class="title-info">Oferta</span></p> <hr>
        
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(o, index) in dadosanalise.ofertas" :key="o.nomeSeguro" :index="index"
            >
            <v-expansion-panel-header v-if="index == indexcod">
                {{ o.nomeSeguro }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p><span><b>Adicional: </b>{{ o.calculo.adicionalFracionamento }}</span></p>
                <p><span><b>Taxa de Juros: </b>{{ o.calculo.taxaJuros }}%</span></p>
                <p><span><b>Custo de Apolice: </b>{{ o.calculo.custoApolice }}</span></p>
                <p><span><b>Valor IS: </b>{{ o.calculo.valorIS }}</span></p>
                <p><span><b>Valor LMG: </b>{{ o.calculo.valorLMG }}</span></p>
                <p><span><b>Taxa de Reativação: </b>{{ o.calculo.taxaReativacao }}</span></p>

                
                <p class="title-info-p"><span class="title-info">Coberturas</span></p> <hr>
                <div class="coberturas">
                    <div class="coberturas-div" v-for="l in o.coberturas" :key="l.nomeCobertura">
                        <p><span><b>Nome cobertura: </b>{{ l.nomeCobertura }}</span></p>
                        <p><span><b>Valor Is: </b>R$ {{ l.valorIS }}</span></p>
                        <p><span><b>Valor LMI: </b>R$ {{ l.valorLMI }}</span></p>
                        <p><span><b>Valor Premio: </b>{{ l.valorPremio }}</span></p>
                    </div>
                </div>
                <p class="title-info-p"><span class="title-info">Beneficios</span></p> <hr>
                <div class="beneficios">
                    <div class="beneficios-div" v-for="p in o.beneficios" :key="p.titulo">
                        <h4>{{ p.titulo }}</h4>
                        <p><span>{{ p.descricao }}</span></p>
                    </div>
                </div>
                <p class="title-info-p"><span class="title-info">Formas de Pagamento</span></p> <hr>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="f in o.formasPagamento" :key="f.nomeFormaPagamento">
                            <v-expansion-panel-header>
                                {{ f.nomeFormaPagamento }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="condicoespagamento">
                                    <div class="condicoespagamento-div" v-for="c in f.condicoesPagamento" :key="c.nomeCondicaoPagamento">
                                        <p><span><b>Condição de Pagamento: </b>{{ c.nomeCondicaoPagamento }}</span></p>
                                        <p><span><b>Primeiro Dia de Vencimento: </b>{{ c.primeiroVencimento }}</span></p>
                                        <p><span><b>Desconto: </b>{{ c.percentualDesconto }}%</span></p>
                                        <p><span><b>Valor das Parcelas: </b>R$ {{ c.valorParcela }}</span></p>
                                        <p><span><b>Valor IOF: </b>R$ {{ c.iof }}</span></p>
                                        <p><span><b>Valor: </b>R$ {{ c.premioBruto }}</span></p>
                                        <button class="btn-finalizar-action2" @click.prevent="selecionarpagamento(o.seguro, f.formaPagamento, c.condicaoPagamento)">Selecionar</button>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>                   
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Cancelar
        </v-btn>
        
  </div>
  <div v-else-if="acesso == '3'" class="containerForm">
    <div class="form-wrapper">
        <button class="btn-cancel-action" @click.prevent="cancelarescolha()">Voltar</button>
        <div v-if="dots2" class="dots2"></div>
        <span id="pdforcamento"></span>
        <!-- <FormulateForm name="form-Locatario-Add" @submit="formLocatarioAdd">    
            <p class="title-info-p"><span class="title-info">Adicionar Locatario</span></p> <hr>
                    <div class="double-wide">
                        <FormulateInput
                            type="text"
                            name="nomelocatario"
                            v-model="nomelocatario"
                            label="Nome Completo"
                            placeholder="Digite o nome completo"
                            validation="required"
                            :validation-messages="{
                                required: 'Campo Obrigatório'
                            }"
                        />
                        <FormulateInput
                            type="text"
                            name="cpfcnpjlocatario"
                            v-model="cpfcnpjlocatario"
                            label="CPF"
                            placeholder="Digite o CPF (Somente os números)"
                            validation="bail|required|number|min:11,length|max:11,length"
                            :validation-messages="{
                                required: 'Campo obrigatório',
                                number: 'Digite somente números',
                                min: 'CPF contém no minimo 11 digitos',
                                max: 'CPF contém no maximo 11 digitos'
                            }"
                        />
                    </div>
                    <div class="double-wide">
                        <FormulateInput
                            type="date"
                            name="dtnasclocatario"
                            v-model="dtnasclocatario"
                            label="Data de Nascimento"
                            validation="required"
                            :validation-messages="{
                                required: 'Campo obrigatório'
                            }"
                        />
                        <FormulateInput
                            type="email"
                            name="locatarioemail"
                            v-model="locatarioemail"
                            label="E-mail"
                            placeholder="Digite o e-mail"
                            validation="bail|required|email"
                            :validation-messages="{
                                required: 'Campo obrigatório',
                                email: 'Digite um e-mail valido'
                            }"
                        />
                    </div>
                    <formulateInput
                        type="text"
                        name="celularlocatario"
                        v-model="celularlocatario"
                        label="Celular"
                        validation='bail|required|number|max:11,length'
                        :validation-messages="{
                            number: 'Digite somente números',
                            required: 'Campo Obrigatório',
                            max: 'Celular contém 11 digitos com o DDD. EX: 11988888888'
                        }"
                        placeholder="Digite o Celular (Pessoa Fisica)"
                    />
                    <FormulateInput
                        type="submit"
                        name="btnlocatariosadd"
                        label="Adicionar Locatario"
                    />
        </FormulateForm> -->
        <p class="title-info-p"><span class="title-info">Locatarios</span></p> <hr>
        <div class="table-cobertura">
                <table>
                    <tr>
                        <th>Nome</th>
                        <th>Titular</th>
                    </tr>
                    <tr v-for="l in dadosanalise.locatarios" :key="l.cpfCnpj">
                        <td>{{ l.nome }}</td>
                        <td>{{ l.titular }}</td>
                    </tr>
                </table>
            </div>
        <FormulateForm @submit="finalizarsegurotoken">
            
            <p class="title-info-p"><span class="title-info">Informação do Locador</span></p> <hr>
            <div class="double-wide">
                <FormulateInput
                    type="text"
                    name="nomelocador"
                    v-model="nomelocador"
                    label="Nome Completo"
                    placeholder="Digite o nome completo"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo Obrigatório'
                    }"
                />
                <FormulateInput
                    type="text"
                    name="cpfcnpjlocador"
                    v-model="cpfcnpjlocador"
                    label="CPF/CNPJ"
                    placeholder="Digite o CPF ou CNPJ (Somente os números)"
                    validation="bail|required|number|min:11,length|max:14,length"
                    :validation-messages="{
                        required: 'Campo obrigatório',
                        number: 'Digite somente números',
                        min: 'CPF contém 11 digitos ou CNPJ contém 14 digitos',
                        max: 'CPF contém 11 digitos ou CNPJ contém 14 digitos'
                    }"
                />
            </div>
            <div class="double-wide">
                <FormulateInput
                    type="date"
                    name="dtnasclocador"
                    v-model="dtnasclocador"
                    label="Data de Nascimento"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="email"
                    name="locadoremail"
                    v-model="locadoremail"
                    label="E-mail"
                    placeholder="Digite o e-mail"
                    validation="bail|required|email"
                    :validation-messages="{
                        required: 'Campo obrigatório',
                        email: 'Digite um e-mail valido'
                    }"
                />
            </div>
            <formulateInput
                type="text"
                name="celularlocador"
                v-model="celularlocador"
                label="Celular"
                validation='bail|required|number|max:11,length'
                :validation-messages="{
                    number: 'Digite somente números',
                    required: 'Campo Obrigatório',
                    max: 'Celular contém 11 digitos com o DDD. EX: 11988888888'
                }"
                placeholder="Digite o Celular (Pessoa Fisica)"
            />
            <FormulateInput
                type="text"
                name="ceplocador"
                v-model="ceplocador"
                label="CEP"
                placeholder="Digite o CEP"
                validation="bail|required|number|min:8,length|max:8,length"
                :validation-messages="{
                    required: 'Campo obrigatório',
                    number: 'Digite somente números',
                    min: 'CEP deve conter 8 digitos',
                    max: 'CEP deve conter 8 digitos'
                }"
            />
           <div class="double-wide">
                <FormulateInput
                    type="select"
                    name="tipologradourolocador"
                    v-model="tipologradourolocador"
                    label="Tipo Logradouro"
                    placeholder="Selecione o tipo de logradouro"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                    :options= optionslogradouro
                />
                <FormulateInput
                    type="text"
                    name="logradourolocador"
                    v-model="logradourolocador"
                    label="Logradouro"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
           </div>
           <div class="double-wide">
                <FormulateInput
                    type="text"
                    name="numerolocador"
                    v-model="numerolocador"
                    label="Número"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="text"
                    name="complementolocado"
                    v-model="complementolocado"
                    label="Complemento"
                />
           </div>
                <FormulateInput
                    type="text"
                    name="bairrolocador"
                    v-model="bairrolocador"
                    label="Bairro"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
            <div class="double-wide">
                <FormulateInput
                    type="text"
                    name="municipiolocador"
                    v-model="municipiolocador"
                    label="Cidade"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="select"
                    name="estadolocador"
                    v-model="estadolocador"
                    label="UF"
                    validation="required"
                    placeholder="Selecione o Estado"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                    :options= estados
                />
            </div>
            <div v-if="pep == '1'">
                <p class="title-info-p"><span class="title-info">Informações sobre PEP - Locador</span></p> <hr>
                <div class="double-wide">
                </div>
                <FormulateInput
                    type="select"
                    name="faixarendapep"
                    v-model="faixarendapep"
                    label="Faixa de Renda"
                    placeholder="Selecione uma faixa de renda"
                    :options="[
                        {value: '001', label: 'Sem Renda'},
                        {value: '002', label: 'Até R$2.500,00'},
                        {value: '003', label: 'De R$2.500,01 até R$5.000,00'},
                        {value: '004', label: 'De R$5.000,01 até R$10.000,00'},
                        {value: '005', label: 'Acima de R$10.000,01'}
                    ]"
                />
                <FormulateInput
                    type="select"
                    name="paisresidenciapep"
                    v-model="paisresidenciapep"
                    label="País de Residência do PEP"
                    placeholder="Selecione um país de residência"
                    :options= paises
                />
            </div>
            <p class="title-info-p"><span class="title-info">Dados Complementares do Imóvel</span></p> <hr>
            <div class="double-wide">
                <FormulateInput
                    type="select"
                    name="tipologradouroimovel"
                    v-model="tipologradouroimovel"
                    label="Tipo Logradouro"
                    placeholder="Selecione o tipo de logradouro"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                    :options= optionslogradouro
                />
                <FormulateInput
                    type="text"
                    name="logradouroimovel"
                    v-model="logradouroimovel"
                    label="Logradouro"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
           </div>
           <div class="double-wide">
                <FormulateInput
                    type="text"
                    name="numeroimovel"
                    v-model="numeroimovel"
                    label="Número"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="text"
                    name="complementoimovel"
                    v-model="complementoimovel"
                    label="Complemento"
                />
           </div>
                <FormulateInput
                    type="text"
                    name="bairroimovel"
                    v-model="bairroimovel"
                    label="Bairro"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
            <div class="double-wide">
                <FormulateInput
                    type="text"
                    name="municipioimovel"
                    v-model="municipioimovel"
                    label="Cidade"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="select"
                    name="estadoimovel"
                    v-model="estadoimovel"
                    label="UF"
                    validation="required"
                    placeholder="Selecione o Estado"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                    :options= estados
                />
            </div>
            
            <div v-if="formapagamento.forma == 'CC' || formapagamento.forma == 'CP'">
                <p class="title-info-p"><span class="title-info">Informações Cartão de Credito</span></p> <hr>
                <FormulateInput
                    type="text"
                    name="numerocartao"
                    v-model="numerocartao"
                    label="Número do Cartão"
                    placeholder="Digite Número do Cartão"
                    validation="bail|number|min:16,length|max:16,length"
                    :validation-messages="{
                        number: 'Digite somente números',
                        min: 'Cartão deve ter 16 digitos',
                        max: 'Cartão deve ter 16 digitos'
                    }"
                />
                <div class="double-wide">
                    <FormulateInput
                        type="select"
                        name="vencimentomes"
                        v-model="vencimentomes"
                        label="Mês Vencimento"
                        :options="[
                            {value: '01', label: '01'},
                            {value: '02', label: '02'},
                            {value: '03', label: '03'},
                            {value: '04', label: '04'},
                            {value: '05', label: '05'},
                            {value: '06', label: '06'},
                            {value: '07', label: '07'},
                            {value: '08', label: '08'},
                            {value: '09', label: '09'},
                            {value: '10', label: '10'},
                            {value: '11', label: '11'},
                            {value: '12', label: '12'}
                        ]"
                    />
                    <FormulateInput
                        type="select"
                        name="vencimentoano"
                        v-model="vencimentoano"
                        label="Ano Vencimento"
                        :options="[
                            {value: '2022', label: '2022'},
                            {value: '2023', label: '2023'},
                            {value: '2024', label: '2024'},
                            {value: '2025', label: '2520'},
                            {value: '2026', label: '2026'},
                            {value: '2027', label: '2027'},
                            {value: '2028', label: '2028'},
                            {value: '2029', label: '2029'},
                            {value: '2030', label: '2030'},
                            {value: '2031', label: '2031'},
                            {value: '2032', label: '2032'},
                            {value: '2033', label: '2033'},
                            {value: '2034', label: '2034'}
                        ]"
                    />
                </div>
                <div class="double-wide">
                    <FormulateInput
                        type="text"
                        name="nomecartao"
                        v-model="nomecartao"
                        label="Nome Titular (Escrito no Cartão)"
                    />
                    <FormulateInput
                        type="text"
                        name="cpfcartao"
                        v-model="cpfcartao"
                        label="CPF do titular"
                        placeholder="Digite seu CPF (Somente os números)"
                        validation="bail|number|min:11,length|max:11,length"
                        :validation-messages="{
                            number: 'Digite somente números',
                            min: 'CPF contém 11 digitos',
                            max: 'CPF contém 11 digitos'
                        }"
                    />
                </div>
            </div>
            <FormulateInput
                type="submit"
                label="Enviar"
                class="btn-finalizar-action"
            />
            <div v-if="dots" class="dots"></div>
        </FormulateForm>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Voltar
        </v-btn>
    </div>
  </div>
  <div v-else-if="acesso == '4'" class="containerForm">
        <FormulateForm @submit="pesquisaorcamentotoken" name="formOrcamento">
            <FormulateInput
                type="text"
                name="orcamentocodigo"
                v-model="orcamentocodigo"
                label="Número do Orçamento"
                placeholder="Digite o número completo do orçamento"
                validation='bail|required|number|min:15,length'
                :validation-messages="{
                    required: 'Campo Obrigatório',
                    number: 'Digite somente os números',
                    min: 'Deve conter 15 digitos'
                }"
            />
            <FormulateInput
                type="submit"
                label="Pesquisar"
                class="btn-finalizar-action"
            />
        </FormulateForm>
        <div v-if="dots" class="dots"></div>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Cancelar
        </v-btn>
    </div>
    <div v-else-if="acesso == '5'" class="containerForm">
        <p class="title-info-p"><span class="title-info">{{ dadosapolice.mensagem }}</span></p> <hr>
        <p><span><b>Número Apolice: </b>{{ dadosapolice.apolice }}</span></p>
        <p><span><b>Endosso: </b>{{ dadosapolice.endosso }}</span></p>
        <p><span><b>Data de Emissão: </b>{{ dadosapolice.dataEmissao }}</span></p>
        <div v-if="dots2" class="dots2"></div>
        <span id="pdfapolicelink"></span>
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="vcard-main">
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-file-pdf-box</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="gerarpdfapolicelink"
                                class="btn-cancel-action"
                                >
                                Gerar o PDF da apolice
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="vcard-main">
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-whatsapp</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="whatsapplinkapolice"
                                class="btn-cancel-action"
                                >
                                Falar Pelo Whatsapp
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>    
    </div>
    <div v-else-if="acesso == '6'" class="containerForm">
        <FormulateForm @submit="pesquisarapolicetoken" name="formApolice">
            <FormulateInput
                type="text"
                name="apolicecodigo"
                v-model="apolicecodigo"
                label="Número do Apolice"
                placeholder="Digite o número completo da apolice"
                validation='bail|required|number|min:15,length'
                :validation-messages="{
                    required: 'Campo Obrigatório',
                    number: 'Digite somente os números',
                    min: 'Deve conter 15 digitos'
                }"
            />
            <FormulateInput
                type="text"
                name="endosso"
                v-model="endosso"
                label="Endosso"
                placeholder="Digite o endosso"
                validation="bail|required|number|min:4,length"
                :validation-messages="{
                    required: 'Campo obrigatorio',
                    number: 'Digite somente números',
                    min: 'Deve conter 4 digitos'
                }"
            />
            <FormulateInput
                type="submit"
                label="Pesquisar"
                class="btn-finalizar-action"
            />
            <span id="pdfapolice"></span>
            <div v-if="dots" class="dots"></div>
        </FormulateForm>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Cancelar
        </v-btn>
    </div>
    <div v-else-if="acesso == '7'" class="containerForm">
        <p class="title-form">Atualizar orçamento.</p>
        <FormulateForm @submit="atualizarDataToken">
            <FormulateInput
                    type="number"
                    name="valorIS"
                    v-model="valor"
                    label="Valor"
                    placeholder="Digite o valor"
                    validation="number"
                />
            <div class="double-wide">
                <FormulateInput
                    type="date"
                    name="iniciocontrato"
                    v-model="iniciocontrato"
                    label="Inicio do Contrato"
                    min="2000-01-01"
                    max="2050-12-31"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
                <FormulateInput
                    type="date"
                    name="fimcontrato"
                    label="Fim do Contrato"
                    :min="iniciocontrato"
                    v-model="fimcontrato"
                    max="2050-12-31"
                    validation="required"
                    :validation-messages="{
                        required: 'Campo obrigatório'
                    }"
                />
            </div>
            <FormulateInput
                type="submit"
                label="Atualizar"
                class="btn-finalizar-action"
            />
        </FormulateForm>
        <div v-if="dots" class="dots"></div>
    </div>
    <div v-else-if="acesso == '8'" class="containerForm2">
        <h2 class="title-form">SUA ANÁLISE FOI APROVADA!</h2>
        <p class="subtitle-form">A presente aprovação tem validade de 45 (quarenta e cinco)dias corridos a partir da data de emissão desta carta. Após esta data, o cadastro deverá ser revalidado. Escolha o melhor plano para sua locação</p>
        <div style="text-align: center; margin-bottom: 32px;">
            <span><b>Número Orçamento: </b>{{ dadosanalise.orcamento }}</span>
        </div>
        <v-container>
            <v-row style="justify-content: center;">
                <!-- <v-col cols="12" md="4">
                    <v-card>
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-arrow-right</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="continuarOnline"
                                >
                                Continuar Online
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card>
                        <v-card-title>
                            <span style="margin:0 auto">
                                <v-icon x-large>mdi-whatsapp</v-icon>
                            </span>
                        </v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                outlined
                                @click.prevent="whatsapplink"
                                >
                                Continuar Pelo Whatsapp
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col> -->
                <v-col v-for="(o, index) in dadosanalise.ofertas" :key="o.nomeSeguro" :index="index" cols="12" md="4">
                    <div class="div-heading title-seguro"><h3>{{ o.nomeSeguro }}</h3></div>
                    <v-card class="vcard-ofertas">
                        <v-card-title class="cardtitle-ofertas">
                            
                            <div class="div-heading"><h5>FORMAS DE PAGAMENTO:</h5></div><hr>
                            <div v-for="f in o.formasPagamento" :key="f.nomeFormaPagamento" class="div-formpag">
                                <div v-for="(c, index) in f.condicoesPagamento" :key="c.nomeCondicaoPagamento" :index="index">
                                    <p v-if="index == (f.condicoesPagamento.length - 1)" class="text-formpag"><strong>{{ f.nomeFormaPagamento }}</strong><br> até {{c.quantidadeParcela}}x de {{ c.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }} <br></p>
                                </div>
                            </div>
                            <div class="div-cobpag">
                                <div class="div-heading"><h5>COBERTURAS:</h5></div><hr>
                                <div>
                                <p v-for="l in o.coberturas" :key="l.nomeCobertura" >
                                    <strong>{{ l.nomeCobertura}}</strong><br> até {{ l.multiploLMI }}x o Aluguel <br>
                                </p> 
                            </div>
                            </div>
                            
                        </v-card-title>
                        <!-- <v-card-actions>
                            
                        </v-card-actions> -->
                    </v-card>
                    <div style="width:100%; text-align: center;">
                        <span style="font-size: 14px; color:red">Limite máximo da indenização da apólice<br>até <strong>{{o.coberturas[0].multiploLMI}} x </strong>Valor do Aluguel</span>
                    </div>
                    <div class="div-btn-fin">
                        <button @click.prevent="gerarpdftoken()" class="btn-fin-on">
                            Baixar Carta Aprovação
                        </button>
                    </div>
                    <div v-if="dots" class="dots"></div>
                    <div class="div-btn-fin">
                        <button @click.prevent="whatsapplink(o.nomeSeguro)" class="btn-fin-wa"><v-icon large>mdi-whatsapp</v-icon>Fale com nossos Especialistas
                        </button>
                    </div> 
                </v-col>
            </v-row>
            <div class="div-btn-pdf"><span id="pdforcamento"></span></div>
            <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Realizar Nova Análise
        </v-btn>
        </v-container>
    </div>
    <div v-else-if="acesso == '9'" class="containerForm">
        <h2 class="title-form">OPS! TENTE NOVAMENTE</h2>
        <p class="subtitle-form">Devido aos critérios de aceitação da companhia, infelizmente não será possível prosseguir com a contratação para o cadastro informado. Você pode fazer uma nova análise ou falar com nossa equipe no Whatsapp, lembrando que temos outras opções de garantias.</p>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Realizar Nova Análise
        </v-btn>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="whatsapplinkerro" class="btn-fin-wa">
            <v-icon large>mdi-whatsapp</v-icon>Fale com nossos Especialistas
        </v-btn>
    </div>
    <div v-else-if="acesso == '10'" class="containerForm2">
        <div v-if="dots" class="dots"></div>
        <h2 class="title-form">Estamos Realizando a Análise.<br>Por Favor Aguarde, em até 30 segundos sua análise estará completa.</h2>
    </div>
    <div v-else-if="acesso == '11'" class="containerForm">
        <h2 class="title-form">OPS! OCORREU UM ERRO</h2>
        <p class="subtitle-form">Tivemos um erro com servidor, tente realizar a análise novamente.</p>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="cancelaropcao" class="btn-cancel-action">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            Realizar Análise Novamente
        </v-btn>
        <v-btn block outlined style="margin: 16px 0px" @click.prevent="whatsapplinkerro" class="btn-fin-wa">
            <v-icon large>mdi-whatsapp</v-icon>Fale com nossos Especialistas
        </v-btn>
    </div>
</div> 
</template>

<script>
import FormulateInput from '@braid/vue-formulate/src/FormulateInput.vue'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ','
  });
export default {
    data() {
        return {
            currencyMask,
            maskcelular: "(##) #####-####",
            maskdate: "##/##/####",
            maskcpf: "###.###.###-##",
            maskcep: "#####-###",
            token: "",
            mensagemerro: "",
            ceporcamento: "",
            nomeorcamento: "",
            dtnasorcamento: "",
            cpforcamento: "",
            emailorcamento: "",
            tabelalocatarios: false,
            celularorcamento: "",
            vigenciaorcamento: "",
            apolicecodigo: "",
            listLocatarios: "",
            endosso: "",
            tipo: "001",
            valor: "",
            locatarios: [],
            acesso: "1",
            dots: false,
            dots2: false,
            iniciocontrato: "",
            fimcontrato: "",
            inicioseguro: "",
            fimseguro: "",
            mesescontrato: 0,
            alugueltipo: false,
            valoraluguel: "",
            orcamentocodigo: '',
            pep: '0',
            peplocatario: '0',
            nomelocador: '',
            cpfcnpjlocador: '',
            dtnasclocador: '',
            locadoremail: '',
            celularlocador: '',
            telcomerciallocador: '',
            ceplocador: '',
            tipologradourolocador: '',
            logradourolocador: '',
            numerolocador: '',
            complementolocado: '',
            bairrolocador: '',
            municipiolocador: '',
            estadolocador: '',
            cepcobranca: '',
            tipologradourocobranca: '',
            logradourocobranca: '',
            numerocobranca: '',
            complementocobranca: '',
            bairrocobranca: '',
            municipiocobranca: '',
            estadocobranca: '',
            dadosorcamentoatualiza: '',
            faixarendapep: '',
            paisresidenciapep: '',
            tipologradouroimovel: '',
            logradouroimovel: '',
            numeroimovel: '',
            complementoimovel: '',
            bairroimovel: '',
            municipioimovel: '',
            estadoimovel: '',
            nomelocatario: '',
            cpfcnpjlocatario: '',
            dtnasclocatario: '',
            locatarioemail: '',
            celularlocatario: '',
            telcomerciallocatario: '',
            faixarendapeplocatario: '',
            paisresidenciapeplocatario: '',
            numerocartao: '',
            vencimentomes: '',
            vencimentoano: '',
            vencimentocartaocod: '',
            nomecartao: '',
            cpfcartao: '',
            indexcod: '0',
            numeroorcamento: '',
            tipocadastro: '0',
            valert1: false,
            valert2: false,
            msgerroalerta: "erro",
            txtpeplocador: '',
            qtdelocatario: 0,
            txtpeplocatario: '',
            formapagamento: {
                seguro: '',
                forma: '',
                condicao: '',
                nomeforma: '',
                valorforma: '',
                valorparcela: ''
            },
            optionslogradouro: [
                            {value: 'A', label: 'AREA'},
                            {value: 'AER', label: 'AEROPORTO'},
                            {value: 'AL', label: 'ALAMEDA'},
                            {value: 'AV', label: 'AVENIDA'},
                            {value: 'CH', label: 'CHACARA'},
                            {value: 'CJ', label: 'CONJUNTO'},
                            {value: 'COL', label: 'COLONIA'},
                            {value: 'CON', label: 'CONDOMINIO'},
                            {value: 'CPO', label: 'CAMPO'},
                            {value: 'DT', label: 'DISTRITO'},
                            {value: 'ESP', label: 'ESPLANADA'},
                            {value: 'EST', label: 'ESTRADA'},
                            {value: 'ETC', label: 'ESTACAO'},
                            {value: 'FAV', label: 'FAVELA'},
                            {value: 'FAZ', label: 'FAZENDA'},
                            {value: 'FRA', label: 'FEIRA'},
                            {value: 'JD', label: 'JARDIM'},
                            {value: 'LD', label: 'LADEIRA'},
                            {value: 'LG', label: 'LAGO'},
                            {value: 'LGA', label: 'LAGOA'},
                            {value: 'LOT', label: 'LOTEAMENTO'},
                            {value: 'LRG', label: 'LARGO'},
                            {value: 'MRO', label: 'MORRO'},
                            {value: 'NUC ', label: 'NUCLEO'},
                            {value: 'O', label: 'OUTROS'},
                            {value: 'PAT', label: 'PATIO'},
                            {value: 'PC', label: 'PRACA'},
                            {value: 'PRQ', label: 'PARQUE'},
                            {value: 'PSA', label: 'PSA'},
                            {value: 'Q', label: 'QUADRA'},
                            {value: 'R', label: 'RUA'},
                            {value: 'REC', label: 'RECANTO'},
                            {value: 'RES', label: 'RESIDENCIAL'},
                            {value: 'ROD', label: 'RODOVIA'},
                            {value: 'SIT', label: 'SITIO'},
                            {value: 'ST', label: 'SETOR'},
                            {value: 'TR', label: 'TRECHO'},
                            {value: 'TRV', label: 'TREVO'},
                            {value: 'TV', label: 'TRAVESSA'},
                            {value: 'V', label: 'VIA'},
                            {value: 'VD', label: 'VIADUTO'},
                            {value: 'VER', label: 'VEREDA'},
                            {value: 'VL', label: 'VILA'},
                            {value: 'VLA', label: 'VIELA'},
                            {value: 'VLE', label: 'VALE'}
                        ],
            estados:[
                {value: 'AC', label: 'Acre'},
                {value: 'AL', label: 'Alagoas'},
                {value: 'AM', label: 'Amazonas'},
                {value: 'AP', label: 'Amapá'},
                {value: 'BA', label: 'Bahia'},
                {value: 'CE', label: 'Ceará'},
                {value: 'DF', label: 'Distrito Federal'},
                {value: 'ES', label: 'Espírito Santo'},
                {value: 'GO', label: 'Goiás'},
                {value: 'MA', label: 'Maranhão'},
                {value: 'MG', label: 'Minas Gerais'},
                {value: 'MS', label: 'Mato Grosso do Sul'},
                {value: 'MT', label: 'Mato Grosso'},
                {value: 'PA', label: 'Pará'},
                {value: 'PB', label: 'Paraíba'},
                {value: 'PE', label: 'Pernambuco'},
                {value: 'PI', label: 'Piauí'},
                {value: 'PR', label: 'Paraná'},
                {value: 'RJ', label: 'Rio de Janeiro'},
                {value: 'RN', label: 'Rio Grande do Norte'},
                {value: 'RO', label: 'Rondônia'},
                {value: 'RR', label: 'Roraima'},
                {value: 'RS', label: 'Rio Grande do Sul'},
                {value: 'SC', label: 'Santa Catarina'},
                {value: 'SE', label: 'Sergipe'},
                {value: 'SP', label: 'São Paulo'},
                {value: 'TO', label: 'Tocantins'}
            ],
            paises:[
  {"value": "00132", "label": "AFEGANISTAO"},
  {"value": "00175", "label": "ALBANIA, REPUBLICA DA"},
  {"value": "00230", "label": "ALEMANHA"},
  {"value": "00310", "label": "BURKINA FASO"},
  {"value": "00370", "label": "ANDORRA"},
  {"value": "00400", "label": "ANGOLA"},
  {"value": "00418", "label": "ANGUILLA"},
  {"value": "00434", "label": "ANTIGUA E BARBUDA"},
  {"value": "00477", "label": "ANTILHAS HOLANDESAS"},
  {"value": "00531", "label": "ARABIA SAUDITA"},
  {"value": "00590", "label": "ARGELIA"},
  {"value": "00639", "label": "ARGENTINA"},
  {"value": "00647", "label": "ARMENIA, REPUBLICA DA"},
  {"value": "00655", "label": "ARUBA"},
  {"value": "00698", "label": "AUSTRALIA"},
  {"value": "00728", "label": "AUSTRIA"},
  {"value": "00736", "label": "AZERBAIJAO, REPUBLICA DO"},
  {"value": "00779", "label": "BAHAMAS, ILHAS"},
  {"value": "00809", "label": "BAHREIN, ILHAS"},
  {"value": "00817", "label": "BANGLADESH"},
  {"value": "00833", "label": "BARBADOS"},
  {"value": "00850", "label": "BELARUS, REPUBLICA DA"},
  {"value": "00876", "label": "BELGICA"},
  {"value": "00884", "label": "BELIZE"},
  {"value": "00906", "label": "BERMUDAS"},
  {"value": "00930", "label": "MIANMAR (BIRMANIA)"},
  {"value": "00973", "label": "BOLIVIA"},
  {"value": "00981", "label": "BOSNIA-HERZEGOVINA (REPUBLICA DA)"},
  {"value": "01015", "label": "BOTSUANA"},
  {"value": "01058", "label": "BRASIL"},
  {"value": "01082", "label": "BRUNEI"},
  {"value": "01112", "label": "BULGARIA, REPUBLICA DA"},
  {"value": "01155", "label": "BURUNDI"},
  {"value": "01198", "label": "BUTAO"},
  {"value": "01279", "label": "CABO VERDE, REPUBLICA DE"},
  {"value": "01376", "label": "CAYMAN, ILHAS"},
  {"value": "01414", "label": "CAMBOJA"},
  {"value": "01457", "label": "CAMAROES"},
  {"value": "01490", "label": "CANADA"},
  {"value": "01504", "label": "GUERNSEY, ILHA DO CANAL (INCLUI ALDERNEY E SARK)"},
  {"value": "01508", "label": "JERSEY, ILHA DO CANAL"},
  {"value": "01511", "label": "CANARIAS, ILHAS"},
  {"value": "01538", "label": "CAZAQUISTAO, REPUBLICA DO"},
  {"value": "01546", "label": "CATAR"},
  {"value": "01589", "label": "CHILE"},
  {"value": "01600", "label": "CHINA, REPUBLICA POPULAR"},
  {"value": "01619", "label": "FORMOSA (TAIWAN)"},
  {"value": "01635", "label": "CHIPRE"},
  {"value": "01651", "label": "COCOS(KEELING),ILHAS"},
  {"value": "01694", "label": "COLOMBIA"},
  {"value": "01732", "label": "COMORES, ILHAS"},
  {"value": "01775", "label": "CONGO"},
  {"value": "01830", "label": "COOK, ILHAS"},
  {"value": "01872", "label": "COREIA, REP.POP.DEMOCRATICA"},
  {"value": "01902", "label": "COREIA, REPUBLICA DA"},
  {"value": "01937", "label": "COSTA DO MARFIM"},
  {"value": "01953", "label": "CROACIA (REPUBLICA DA)"},
  {"value": "01961", "label": "COSTA RICA"},
  {"value": "01988", "label": "COVEITE"},
  {"value": "01996", "label": "CUBA"},
  {"value": "02291", "label": "BENIN"},
  {"value": "02321", "label": "DINAMARCA"},
  {"value": "02356", "label": "DOMINICA,ILHA"},
  {"value": "02399", "label": "EQUADOR"},
  {"value": "02402", "label": "EGITO"},
  {"value": "02437", "label": "ERITREIA"},
  {"value": "02445", "label": "EMIRADOS ARABES UNIDOS"},
  {"value": "02453", "label": "ESPANHA"},
  {"value": "02461", "label": "ESLOVENIA, REPUBLICA DA"},
  {"value": "02470", "label": "ESLOVACA, REPUBLICA"},
  {"value": "02496", "label": "ESTADOS UNIDOS"},
  {"value": "02518", "label": "ESTONIA, REPUBLICA DA"},
  {"value": "02534", "label": "ETIOPIA"},
  {"value": "02550", "label": "FALKLAND (ILHAS MALVINAS)"},
  {"value": "02593", "label": "FEROE, ILHAS"},
  {"value": "02674", "label": "FILIPINAS"},
  {"value": "02712", "label": "FINLANDIA"},
  {"value": "02755", "label": "FRANCA"},
  {"value": "02810", "label": "GABAO"},
  {"value": "02852", "label": "GAMBIA"},
  {"value": "02895", "label": "GANA"},
  {"value": "02917", "label": "GEORGIA, REPUBLICA DA"},
  {"value": "02933", "label": "GIBRALTAR"},
  {"value": "02976", "label": "GRANADA"},
  {"value": "03018", "label": "GRECIA"},
  {"value": "03050", "label": "GROENLANDIA"},
  {"value": "03093", "label": "GUADALUPE"},
  {"value": "03131", "label": "GUAM"},
  {"value": "03174", "label": "GUATEMALA"},
  {"value": "03255", "label": "GUIANA FRANCESA"},
  {"value": "03298", "label": "GUINE"},
  {"value": "03310", "label": "GUINE-EQUATORIAL"},
  {"value": "03344", "label": "GUINE-BISSAU"},
  {"value": "03379", "label": "GUIANA"},
  {"value": "03417", "label": "HAITI"},
  {"value": "03450", "label": "HONDURAS"},
  {"value": "03514", "label": "HONG KONG"},
  {"value": "03557", "label": "HUNGRIA, REPUBLICA DA"},
  {"value": "03573", "label": "IEMEN"},
  {"value": "03595", "label": "MAN, ILHA DE"},
  {"value": "03611", "label": "INDIA"},
  {"value": "03654", "label": "INDONESIA"},
  {"value": "03697", "label": "IRAQUE"},
  {"value": "03727", "label": "IRA, REPUBLICA ISLAMICA DO"},
  {"value": "03751", "label": "IRLANDA"},
  {"value": "03794", "label": "ISLANDIA"},
  {"value": "03832", "label": "ISRAEL"},
  {"value": "03867", "label": "ITALIA"},
  {"value": "03913", "label": "JAMAICA"},
  {"value": "03964", "label": "JOHNSTON, ILHAS"},
  {"value": "03999", "label": "JAPAO"},
  {"value": "04030", "label": "JORDANIA"},
  {"value": "04111", "label": "KIRIBATI"},
  {"value": "04200", "label": "LAOS, REP.POP.DEMOCR.DO"},
  {"value": "04235", "label": "LEBUAN,ILHAS"},
  {"value": "04260", "label": "LESOTO"},
  {"value": "04278", "label": "LETONIA, REPUBLICA DA"},
  {"value": "04316", "label": "LIBANO"},
  {"value": "04340", "label": "LIBERIA"},
  {"value": "04383", "label": "LIBIA"},
  {"value": "04405", "label": "LIECHTENSTEIN"},
  {"value": "04421", "label": "LITUANIA, REPUBLICA DA"},
  {"value": "04456", "label": "LUXEMBURGO"},
  {"value": "04472", "label": "MACAU"},
  {"value": "04499", "label": "MACEDONIA, ANT.REP.IUGOSLAVA"},
  {"value": "04502", "label": "MADAGASCAR"},
  {"value": "04525", "label": "MADEIRA, ILHA DA"},
  {"value": "04553", "label": "MALASIA"},
  {"value": "04588", "label": "MALAVI"},
  {"value": "04618", "label": "MALDIVAS"},
  {"value": "04642", "label": "MALI"},
  {"value": "04677", "label": "MALTA"},
  {"value": "04723", "label": "MARIANAS DO NORTE"},
  {"value": "04740", "label": "MARROCOS"},
  {"value": "04766", "label": "MARSHALL,ILHAS"},
  {"value": "04774", "label": "MARTINICA"},
  {"value": "04855", "label": "MAURICIO"},
  {"value": "04880", "label": "MAURITANIA"},
  {"value": "04901", "label": "MIDWAY, ILHAS"},
  {"value": "04936", "label": "MEXICO"},
  {"value": "04944", "label": "MOLDAVIA, REPUBLICA DA"},
  {"value": "04952", "label": "MONACO"},
  {"value": "04979", "label": "MONGOLIA"},
  {"value": "04985", "label": "MONTENEGRO"},
  {"value": "04995", "label": "MICRONESIA"},
  {"value": "05010", "label": "MONTSERRAT,ILHAS"},
  {"value": "05053", "label": "MOCAMBIQUE"},
  {"value": "05070", "label": "NAMIBIA"},
  {"value": "05088", "label": "NAURU"},
  {"value": "05118", "label": "CHRISTMAS,ILHA (NAVIDAD)"},
  {"value": "05177", "label": "NEPAL"},
  {"value": "05215", "label": "NICARAGUA"},
  {"value": "05258", "label": "NIGER"},
  {"value": "05282", "label": "NIGERIA"},
  {"value": "05312", "label": "NIUE,ILHA"},
  {"value": "05355", "label": "NORFOLK,ILHA"},
  {"value": "05380", "label": "NORUEGA"},
  {"value": "05428", "label": "NOVA CALEDONIA"},
  {"value": "05452", "label": "PAPUA NOVA GUINE"},
  {"value": "05487", "label": "NOVA ZELANDIA"},
  {"value": "05517", "label": "VANUATU"},
  {"value": "05568", "label": "OMA"},
  {"value": "05665", "label": "PACIFICO,ILHAS DO (POSSESSAO DOS EUA)"},
  {"value": "05738", "label": "PAISES BAIXOS (HOLANDA)"},
  {"value": "05754", "label": "PALAU"},
  {"value": "05762", "label": "PAQUISTAO"},
  {"value": "05800", "label": "PANAMA"},
  {"value": "05860", "label": "PARAGUAI"},
  {"value": "05894", "label": "PERU"},
  {"value": "05932", "label": "PITCAIRN,ILHA"},
  {"value": "05991", "label": "POLINESIA FRANCESA"},
  {"value": "06033", "label": "POLONIA, REPUBLICA DA"},
  {"value": "06076", "label": "PORTUGAL"},
  {"value": "06114", "label": "PORTO RICO"},
  {"value": "06238", "label": "QUENIA"},
  {"value": "06254", "label": "QUIRGUIZ, REPUBLICA"},
  {"value": "06289", "label": "REINO UNIDO"},
  {"value": "06408", "label": "REPUBLICA CENTRO-AFRICANA"},
  {"value": "06475", "label": "REPUBLICA DOMINICANA"},
  {"value": "06602", "label": "REUNIAO, ILHA"},
  {"value": "06653", "label": "ZIMBABUE"},
  {"value": "06700", "label": "ROMENIA"},
  {"value": "06750", "label": "RUANDA"},
  {"value": "06769", "label": "RUSSIA, FEDERACAO DA"},
  {"value": "06777", "label": "SALOMAO, ILHAS"},
  {"value": "06781", "label": "SAINT KITTS E NEVIS"},
  {"value": "06858", "label": "SAARA OCIDENTAL"},
  {"value": "06874", "label": "EL SALVADOR"},
  {"value": "06904", "label": "SAMOA"},
  {"value": "06912", "label": "SAMOA AMERICANA"},
  {"value": "06955", "label": "SAO CRISTOVAO E NEVES,ILHAS"},
  {"value": "06971", "label": "SAN MARINO"},
  {"value": "07005", "label": "SAO PEDRO E MIQUELON"},
  {"value": "07056", "label": "SAO VICENTE E GRANADINAS"},
  {"value": "07102", "label": "SANTA HELENA"},
  {"value": "07153", "label": "SANTA LUCIA"},
  {"value": "07285", "label": "SENEGAL"},
  {"value": "07315", "label": "SEYCHELLES"},
  {"value": "07358", "label": "SERRA LEOA"},
  {"value": "07370", "label": "SERVIA"},
  {"value": "07412", "label": "CINGAPURA"},
  {"value": "07447", "label": "SIRIA, REPUBLICA ARABE DA"},
  {"value": "07480", "label": "SOMALIA"},
  {"value": "07501", "label": "SRI LANKA"},
  {"value": "07544", "label": "SUAZILANDIA"},
  {"value": "07560", "label": "AFRICA DO SUL"},
  {"value": "07595", "label": "SUDAO"},
  {"value": "07641", "label": "SUECIA"},
  {"value": "07676", "label": "SUICA"},
  {"value": "07706", "label": "SURINAME"},
  {"value": "07722", "label": "TADJIQUISTAO, REPUBLICA DO"},
  {"value": "07765", "label": "TAILANDIA"},
  {"value": "07803", "label": "TANZANIA, REP.UNIDA DA"},
  {"value": "07820", "label": "TERRITORIO BRIT.OC.INDICO"},
  {"value": "07838", "label": "DJIBUTI"},
  {"value": "07889", "label": "CHADE"},
  {"value": "07919", "label": "TCHECA, REPUBLICA"},
  {"value": "07951", "label": "TIMOR LESTE"},
  {"value": "08001", "label": "TOGO"},
  {"value": "08052", "label": "TOQUELAU,ILHAS"},
  {"value": "08109", "label": "TONGA"},
  {"value": "08150", "label": "TRINIDAD E TOBAGO"},
  {"value": "08206", "label": "TUNISIA"},
  {"value": "08230", "label": "TURCAS E CAICOS,ILHAS"},
  {"value": "08249", "label": "TURCOMENISTAO, REPUBLICA DO"},
  {"value": "08273", "label": "TURQUIA"},
  {"value": "08281", "label": "TUVALU"},
  {"value": "08311", "label": "UCRANIA"},
  {"value": "08338", "label": "UGANDA"},
  {"value": "08451", "label": "URUGUAI"},
  {"value": "08478", "label": "UZBEQUISTAO, REPUBLICA DO"},
  {"value": "08486", "label": "VATICANO, EST.DA CIDADE DO"},
  {"value": "08508", "label": "VENEZUELA"},
  {"value": "08583", "label": "VIETNA"},
  {"value": "08630", "label": "VIRGENS,ILHAS (BRITANICAS)"},
  {"value": "08664", "label": "VIRGENS,ILHAS (E.U.A.)"},
  {"value": "08702", "label": "FIJI"},
  {"value": "08737", "label": "WAKE, ILHA"},
  {"value": "08885", "label": "CONGO, REPUBLICA DEMOCRATICA DO"},
  {"value": "08907", "label": "ZAMBIA"}
  ],
            dadosapolice: {},
            dadosanalise: {},
        }
    },
    methods: {
        openorcamento(){
            this.acesso = '1'
        },
        openpesquisaorcamento(){
            this.acesso = '4'
        },
        openpesquisaapolice(){
            this.acesso = '6'
        },
        add(){
            this.valert1 = false;
            if(this.qtdelocatario < 10){
                const sameTipo = t => t.cpf === this.cpforcamento;
                const reallyNew = this.locatarios.filter(sameTipo).length == 0;
                if (reallyNew) {
                        if(this.validarCPF()){
                            if(this.getAge() >= 18){
                                this.locatarios.push({
                                    cpf: this.cpforcamento,
                                    email: this.emailorcamento,
                                    celular: this.celularorcamento,
                                    nome: this.nomeorcamento,
                                    dtnasc: this.dtnasorcamento.split('-').reverse().join('/')
                                });
                                this.$formulate.reset('form-locatario')
                                this.qtdelocatario += 1
                                this.tabelalocatarios = true
                            }
                            else {
                                this.msgerroalerta = 'Não é permitido menor de idade!'
                                this.valert1 = true
                            }    
                        } else{
                           this.msgerroalerta = 'CPF invalido! Verifique os números'
                            this.valert1 = true
                        }
                }
                else {
                    this.msgerroalerta = 'CPF já cadastrado!'
                    this.valert1 = true
                }
            } else {
                this.msgerroalerta = 'Limite de 10 Locatarios'
                this.valert1 = true
            }
        },
        deletelocatario(cpf) {
            const newLocatarios = this.locatarios.filter((item) => item.cpf !== cpf);
            this.locatarios = newLocatarios;
            this.qtdelocatario -= 1
            if(this.qtdelocatario == 0){this.tabelalocatarios = false}
        },
        selecionarpagamento(seguro, forma, condicao) {
            this.formapagamento.seguro = seguro
            this.formapagamento.forma = forma
            this.formapagamento.condicao = condicao
            this.acesso = '3'
            // console.log(this.dadosanalise)
            window.scrollTo(0, 0);
        },
        errogerado(dados){
            this.mensagemerro = dados.message
            this.dots = false
            this.acesso = '11'
            // console.log(this.mensagemerro)
        },
        async submitHandler() {
            
            var myHeaders = new Headers()
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
            myHeaders.append("Content-Type", "application/json")
            var body = JSON.stringify({
                "grant_type": "client_credentials"
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body
            }
            fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                .then(response => response.json())
                .then(result => this.enviarorcamento(result.access_token))
                .catch(error => this.errogerado(error))


        },
        enviarorcamento(token){
            // console.log("Token: "+token)
            var coberturaslist = ""
            var codCoberturaid = ""
            let valor1 = this.valor
            let valor2 = parseFloat(valor1.replace(/[^0-9]/g, '')).toFixed(2)
            if(valor2 >= 200 && valor2 <= 10000){
                this.dots = true
                this.acesso = '10' 
                for(let i=0; i < 10; i++){
                if(!i==0){
                    coberturaslist += ", "
                }
                if(i==9){
                    codCoberturaid = "010"
                } else {
                    var idcod = i + 1
                    codCoberturaid = "00"+idcod
                }
                coberturaslist += '{ "codCobertura": "'+ codCoberturaid +'", "valorIS": '+valor2+' }'
            }

            var locatariosList = ""
            var titular = "S"
            for(let i=0; i < this.locatarios.length; i++){
                if(!i==0){
                    locatariosList += ", "
                    titular = "N"
                }
                locatariosList += '{"nome": "'+this.locatarios[i].nome+'", "dataNascimento": "'+this.locatarios[i].dtnasc+'", "cpfCnpj": "'+this.locatarios[i].cpf.replace(/-/g, "").replace(/\./g, "")+'", "titular": "'+titular+'", "email": "'+this.locatarios[i].email+'", "telefoneCelular": "'+this.locatarios[i].celular.replace(/[^0-9]/g, '')+'"}'
            }

            var timeElapsed = Date.now();
            var datenow =  new Date(timeElapsed)
            var datahoje = new Date(timeElapsed)
            var datafinal = new Date(datahoje.setMonth(datahoje.getMonth() + parseInt(this.mesescontrato)))

            // var ic = datahoje.split('-').reverse().join('/');
            // var fc = datafinal.split('-').reverse().join('/');
            var ic = datenow.toLocaleDateString();
            var fc = datafinal.toLocaleDateString();

            const dadosorcamento = '{ "susep": "37P60J", "cep": "'+this.ceporcamento.replace(/-/g, "")+'", "locatarios": ['+locatariosList+'],"coberturas": ['+ coberturaslist +'], "contrato": { "tipoVigenciaContrato": "1", "inicioContrato": "'+ic+'", "fimContrato": "'+fc+'", "inicioSeguro": "'+ic+'", "fimSeguro": "'+fc+'"}}'

            //  console.log(dadosorcamento)

            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'POST',
                headers: myHeaders2,
                body: dadosorcamento
            }
            fetch("https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/orcamentos", requestOptions2)
                .then(response => response.json())
                .then(result => this.pesquisaorcamentotoken2(result))
                .catch(error => this.errogerado(error))
            
            }
            else {
                this.msgerroalerta = 'Valor do aluguel dever ser entre R$ 200,00 a R$ 10.000,00'
                this.valert2 = true
            }
            
        },
        async pesquisaorcamentotoken(){
            this.dots = true
            
            var myHeaders = new Headers()
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
            myHeaders.append("Content-Type", "application/json")
            var body = JSON.stringify({
                "grant_type": "client_credentials"
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body
            }
            fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                .then(response => response.json())
                .then(result => this.pesquisaorcamento(result.access_token))
                .catch(error => this.errogerado(error))
        },
        pesquisaorcamento(token){
            var url = "https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/orcamentos/"+this.orcamentocodigo+"/37P60J";
            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'GET',
                headers: myHeaders2
            }
            fetch(url, requestOptions2)
                .then(response => response.json())
                .then(result => this.pdfcartatoken(result))
                .catch(error => this.errogerado(error))
            
        },
        async pesquisaorcamentotoken2(dados){
            this.dots = true
            if(dados.orcamento){
                this.numeroorcamento = dados.orcamento
                var myHeaders = new Headers()
                myHeaders.append("Accept", "application/json")
                myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                myHeaders.append("Content-Type", "application/json")
                var body = JSON.stringify({
                    "grant_type": "client_credentials"
                })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: body
                }
                fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                    .then(response => response.json())
                    .then(result => this.pesquisaorcamento2(result.access_token))
                    .catch(error => this.errogerado(error))   
            } else {
                if(dados.errorCode == 409){
                    this.acesso = '9'
                } else {
                    this.acesso = '1'
                    alert(dados.errorMessage)
                }
                this.dots = false
            }
        },
        pesquisaorcamento2(token){
            var url = "https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/orcamentos/"+this.numeroorcamento+"/37P60J";
            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'GET',
                headers: myHeaders2
            }
            fetch(url, requestOptions2)
                .then(response => response.json())
                .then(result => this.pdfcartatoken(result))
                .catch(error => this.errogerado(error))
            
        },
        pdfcartatoken(dados){
            this.dadosanalise = dados
            // console.log(this.dadosanalise)
            for(let i=0; i < this.dadosanalise.locatarios.length; i++){
                this.listLocatarios += '{"nome": "'+this.dadosanalise.locatarios[i].nome+'", "dataNascimento": "'+this.dadosanalise.locatarios[i].dataNascimento+'", "cpfCnpj": "'+this.dadosanalise.locatarios[i].cpfCnpj+'", "titular": "'+this.dadosanalise.locatarios[i].titular+'", "email": "'+this.dadosanalise.locatarios[i].email+'", "telefoneCelular": "'+this.dadosanalise.locatarios[i].telefoneCelular+'"}'
            }
            // console.log(this.listLocatarios)
            if(this.dadosanalise.apolice){
                if(this.dadosanalise.apolice.codigo == ""){
                    this.dots = false
                    // this.dots2 = true
                    this.acesso = "8";
                    window.scrollTo(0, 0);
                    var myHeaders = new Headers()
                    myHeaders.append("Accept", "application/json")
                    myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                    myHeaders.append("Content-Type", "application/json")
                    var body = JSON.stringify({
                        "grant_type": "client_credentials"
                    })
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: body
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                        .then(response => response.json())
                        .then(console.log('ok'))
                        .catch(error => this.errogerado(error))
                } else {
                    alert("Apolice nº "+this.dadosanalise.apolice.codigo+" , já gerado com esse orçamento")
                    this.dots = false
                    this.$formulate.reset('formOrcamento')
                }
            } else{
                this.dots = false
                    // this.dots2 = true
                    this.acesso = "8";
                    window.scrollTo(0, 0);
                    var myHeaders2 = new Headers()
                    myHeaders2.append("Accept", "application/json")
                    myHeaders2.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                    myHeaders2.append("Content-Type", "application/json")
                    var body2 = JSON.stringify({
                        "grant_type": "client_credentials"
                    })
                    var requestOptions2 = {
                        method: 'POST',
                        headers: myHeaders2,
                        body: body2
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions2)
                        .then(response => response.json())
                        .then(console.log('ok'))
                        .catch(error => this.errogerado(error))
            }
            
        },
        gerarpdftoken(){
            this.dots = true
            var myHeaders = new Headers()
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
            myHeaders.append("Content-Type", "application/json")
            var body = JSON.stringify({
                "grant_type": "client_credentials"
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body
            }
            fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                .then(response => response.json())
                .then(result => this.pdfcarta(result.access_token))
                .catch(error => this.errogerado(error))
        },
        pdfcarta(token){
            var url = "https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/orcamentos/"+this.dadosanalise.orcamento+"/37P60J/pdf/carta-aprovacao";
            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'GET',
                headers: myHeaders2
            }
            fetch(url, requestOptions2)
                .then(response => response.json())
                .then(result => this.pdfcartamostrar(result))
                .catch(error => this.errogerado(error))
        },
        pdfcartamostrar(pdf){
            this.dots = false
            if(pdf.data.file){
                var link = document.createElement('a');
                link.innerHTML = 'Download PDF';
                link.style.visibility = 'hidden';
                link.download = 'carta.pdf';
                link.href = 'data:application/octet-stream;base64,' + pdf.data.file;
                document.getElementById('pdforcamento').appendChild(link);
                document.querySelector('#pdforcamento > a').click();
            } else {alert('Erro ao gerar o PDF, tente Novamente!')}
        },
        cancelarescolha(){
            this.acesso = '2'
            window.scrollTo(0, 0);
        },
        async finalizarsegurotoken(){
            this.dots = true

            if(this.formapagamento.forma == 'CC' || this.formapagamento.forma == 'CP'){
                if(this.tipocadastro == '0'){
                    //realizar o chamado do token para codificar cartão
                    var myHeaders = new Headers()
                    myHeaders.append("Accept", "application/json")
                    myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                    myHeaders.append("Content-Type", "application/json")
                    var body = JSON.stringify({
                        "grant_type": "client_credentials"
                    })
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: body
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                        .then(response => response.json())
                        .then(result => this.finalizarseguro(result.access_token))
                        .catch(error => this.errogerado(error))
                        
                } else if(this.tipocadastro == '1'){
                    var myHeaders2 = new Headers()
                    myHeaders2.append("Accept", "application/json")
                    myHeaders2.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                    myHeaders2.append("Content-Type", "application/json")
                    var body2 = JSON.stringify({
                        "grant_type": "client_credentials"
                    })
                    var requestOptions2 = {
                        method: 'POST',
                        headers: myHeaders2,
                        body: body2
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions2)
                        .then(response => response.json())
                        .then(result => this.finalizarseguro(result.access_token))
                        .catch(error => this.errogerado(error))
                } else {
                    //realizar chamado do token para finalizar
                    var myHeaders4 = new Headers()
                    myHeaders4.append("Accept", "application/json")
                    myHeaders4.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                    myHeaders4.append("Content-Type", "application/json")
                    var body4 = JSON.stringify({
                        "grant_type": "client_credentials"
                    })
                    var requestOptions4 = {
                        method: 'POST',
                        headers: myHeaders4,
                        body: body4
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions4)
                        .then(response => response.json())
                        .then(result => this.finalizarseguro(result.access_token))
                        .catch(error => this.errogerado(error))
                }
            } else {
                // realizar chamado do token sem cartão
                this.tipocadastro = '3'
                var myHeaders3 = new Headers()
                    myHeaders3.append("Accept", "application/json")
                    myHeaders3.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
                    myHeaders3.append("Content-Type", "application/json")
                    var body3 = JSON.stringify({
                        "grant_type": "client_credentials"
                    })
                    var requestOptions3 = {
                        method: 'POST',
                        headers: myHeaders3,
                        body: body3
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions3)
                        .then(response => response.json())
                        .then(result => this.finalizarseguro(result.access_token))
                        .catch(error => this.errogerado(error))
            }
            
        },
        finalizarseguro(token){
            if(this.tipocadastro == '0'){
                // codifica numero cartão
                this.tipocadastro = '1'
                var myHeaders = new Headers()
                    myHeaders.append("Accept", "application/json")
                    myHeaders.append("Authorization", "Bearer "+ token)
                    myHeaders.append("Content-Type", "application/json")
                    var body = '{"texto": "'+this.numerocartao+'"}'
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: body
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/criptografias", requestOptions)
                        .then(response => response.json())
                        .then(result => this.salvarcartaocod(result.textoCriptografado, '1'))
                        .catch(error => this.errogerado(error))
                
            } else if(this.tipocadastro == '1'){
                // codifica validade cartão
                this.tipocadastro = '2'
                var myHeaders2 = new Headers()
                    myHeaders2.append("Accept", "application/json")
                    myHeaders2.append("Authorization", "Bearer "+ token)
                    myHeaders2.append("Content-Type", "application/json")
                    var body2 = '{"texto": "'+this.numerocartao+'"}'
                    var requestOptions2 = {
                        method: 'POST',
                        headers: myHeaders2,
                        body: body2
                    }
                    fetch("https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/criptografias", requestOptions2)
                        .then(response => response.json())
                        .then(result => this.salvarcartaocod(result.textoCriptografado, '2'))
                        .catch(error => this.errogerado(error))
            } else {

                //finalização do cadastro
                    if(this.tipocadastro == '2'){
                        // cadastro com cartão
                        var myHeaders3 = new Headers()
                        myHeaders3.append("Accept", "application/json")
                        myHeaders3.append("Authorization", "Bearer "+ token)
                        myHeaders3.append("Content-Type", "application/json")
                        var body3 = '{ "seguro": "'+this.formapagamento.seguro+'", "cobranca": { "formaPagamento": "'+this.formapagamento.forma+'", "condicaoPagamento": "'+this.formapagamento.condicao+'", "cartaoCredito": { "numeroCartao": "'+this.numerocartao+'", "titularCartao": "'+this.nomecartao+'", "cpfCnpjTitular": "'+this.cpfcartao+'", "validadeCartao": "'+this.vencimentocartaocod+'"}}, "locador": { "cpfCnpj": "'+this.cpfcnpjlocador+'", "nome": "'+this.nomelocador+'", "dataNascimento": "'+this.dtnasclocador.split('-').reverse().join('/')+'", "email": "'+this.locadoremail+'", "telefoneCelular": "'+this.celularlocador+'", "enderecoPrincipal": { "cep": "'+this.ceplocador+'", "tipoLogradouro": "'+this.tipologradourolocador+'", "logradouro": "'+this.logradourolocador+'", "numero": "'+this.numerolocador+'", "complemento": "'+this.complementolocado+'", "bairro": "'+this.bairrolocador+'", "municipio": "'+this.municipiolocador+'", "estado": "'+this.estadolocador+'"}, "enderecoCobranca": { "cep": "'+this.ceplocador+'", "tipoLogradouro": "'+this.tipologradourolocador+'", "logradouro": "'+this.logradourolocador+'", "numero": "'+this.numerolocador+'", "complemento": "'+this.complementolocado+'", "bairro": "'+this.bairrolocador+'", "municipio": "'+this.municipiolocador+'", "estado": "'+this.estadolocador+'"}}, "localRisco": { "tipoLogradouro": "'+this.tipologradouroimovel+'", "logradouro": "'+this.logradouroimovel+'", "numero": "'+this.numeroimovel+'", "complemento": "'+this.complementoimovel+'", "bairro": "'+this.bairroimovel+'", "municipio": "'+this.municipioimovel+'", "estado": "'+this.estadoimovel+'"}, "locatarios": ['+this.listLocatarios+']}'

                        // console.log(body3)
                        var requestOptions3 = {
                            method: 'PUT',
                            headers: myHeaders3,
                            body: body3
                        }
                        fetch("https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/propostas/"+this.dadosanalise.orcamento+"/37P60J", requestOptions3)
                            .then(response => response.json())
                            .then(result => this.mostrarapolice(result))
                            .catch(error => this.errogerado(error))

                    } else {
                        // cadastro sem cartão
                        var myHeaders4 = new Headers()
                        myHeaders4.append("Accept", "application/json")
                        myHeaders4.append("Authorization", "Bearer "+ token)
                        myHeaders4.append("Content-Type", "application/json")
                        var body4 = '{ "seguro": "'+this.formapagamento.seguro+'", "cobranca": { "formaPagamento": "'+this.formapagamento.forma+'", "condicaoPagamento": "'+this.formapagamento.condicao+'"}, "locador": { "cpfCnpj": "'+this.cpfcnpjlocador+'", "nome": "'+this.nomelocador+'", "dataNascimento": "'+this.dtnasclocador.split('-').reverse().join('/')+'", "email": "'+this.locadoremail+'", "telefoneCelular": "'+this.celularlocador+'", "enderecoPrincipal": { "cep": "'+this.ceplocador+'", "tipoLogradouro": "'+this.tipologradourolocador+'", "logradouro": "'+this.logradourolocador+'", "numero": "'+this.numerolocador+'", "complemento": "'+this.complementolocado+'", "bairro": "'+this.bairrolocador+'", "municipio": "'+this.municipiolocador+'", "estado": "'+this.estadolocador+'"}, "enderecoCobranca": { "cep": "'+this.ceplocador+'", "tipoLogradouro": "'+this.tipologradourolocador+'", "logradouro": "'+this.logradourolocador+'", "numero": "'+this.numerolocador+'", "complemento": "'+this.complementolocado+'", "bairro": "'+this.bairrolocador+'", "municipio": "'+this.municipiolocador+'", "estado": "'+this.estadolocador+'"}}, "localRisco": { "tipoLogradouro": "'+this.tipologradouroimovel+'", "logradouro": "'+this.logradouroimovel+'", "numero": "'+this.numeroimovel+'", "complemento": "'+this.complementoimovel+'", "bairro": "'+this.bairroimovel+'", "municipio": "'+this.municipioimovel+'", "estado": "'+this.estadoimovel+'"}, "locatarios": ['+this.listLocatarios+']}'

                        // console.log(body4)
                        var requestOptions4 = {
                            method: 'PUT',
                            headers: myHeaders4,
                            body: body4
                        }
                        fetch("https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/propostas/"+this.dadosanalise.orcamento+"/37P60J", requestOptions4)
                            .then(response => response.json())
                            .then(result => this.mostrarapolice(result))
                            .catch(error => this.errogerado(error))

                    }
            }
            // console.log(token)
        },
        salvarcartaocod(texto, cod){
            if(cod == '1'){
                this.numerocartao = texto
                this.finalizarsegurotoken()
            } else{
                this.vencimentocartaocod = texto
                this.finalizarsegurotoken()
            }
        },
        mostrarapolice(dados){
            this.dadosapolice = dados
            this.acesso = '5'
            // console.log(dados)
        },
        async pesquisarapolicetoken(){
            this.dots = true
            
            var myHeaders = new Headers()
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
            myHeaders.append("Content-Type", "application/json")
            var body = JSON.stringify({
                "grant_type": "client_credentials"
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body
            }
            fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                .then(response => response.json())
                .then(result => this.pesquisarapolice(result.access_token))
                .catch(error => this.errogerado(error))
        },
        pesquisarapolice(token){
            var url = "https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/apolices/"+this.apolicecodigo+"/"+this.endosso+"/37P60J/pdf";
            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'GET',
                headers: myHeaders2
            }
            fetch(url, requestOptions2)
                .then(response => response.json())
                .then(result => this.pdfapolice(result.data.file))
                .catch(error => this.errogerado(error))
        },
        pdfapolice(pdf){
            this.dots = false
            var link = document.createElement('a');
            link.innerHTML = 'Download PDF';
            link.download = 'apolice.pdf';
            link.href = 'data:application/octet-stream;base64,' + pdf;
            document.getElementById('pdfapolice').appendChild(link);
        },
        async pdfapolicetoken(){            
            var myHeaders = new Headers()
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
            myHeaders.append("Content-Type", "application/json")
            var body = JSON.stringify({
                "grant_type": "client_credentials"
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body
            }
            fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                .then(response => response.json())
                .then(result => this.pdfapolicegerar(result.access_token))
                .catch(error => this.errogerado(error))
        },
        pdfapolicegerar(token){
            var url = "https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/apolices/"+this.apolicecodigo+"/"+this.endosso+"/37P60J/pdf";
            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'GET',
                headers: myHeaders2
            }
            fetch(url, requestOptions2)
                .then(response => response.json())
                .then(result => this.apolicepdf(result.data.file))
                .catch(error => this.errogerado(error))
        },
        apolicepdf(pdf){
            this.dots2 = false
            var link = document.createElement('a');
            link.innerHTML = 'Download PDF';
            link.download = 'apolice.pdf';
            link.href = 'data:application/octet-stream;base64,' + pdf;
            document.getElementById('pdfapolicelink').appendChild(link);
        },
        async formLocatarioAdd(){
            this.$formulate.reset('form-Locatario-Add')
        },
        cancelaropcao(){
            document.location.reload(true);
        },
        async atualizarDataToken(){
            this.dots = true
            var coberturaslist = ""
            var codCoberturaid = ""
            for(let i=0; i < 10; i++){
                if(!i==0){
                    coberturaslist += ", "
                }
                if(i==9){
                    codCoberturaid = "010"
                } else {
                    var idcod = i + 1
                    codCoberturaid = "00"+idcod
                }
                coberturaslist += '{ "codCobertura": "'+ codCoberturaid +'", "valorIS": '+parseFloat(this.valor).toFixed(2)+' }'
            }

            var ic = this.iniciocontrato.split('-').reverse().join('/');
            var fc = this.fimcontrato.split('-').reverse().join('/');

            this.dadosorcamentoatualiza = '{ "coberturas": ['+ coberturaslist +'], "contrato": { "tipoVigenciaContrato": "1", "inicioContrato": "'+ic+'", "fimContrato": "'+fc+'", "inicioSeguro": "'+ic+'", "fimSeguro": "'+fc+'"}}'

        },
        gerartokenAtualiza(){
            var myHeaders = new Headers()
            myHeaders.append("Accept", "application/json")
            myHeaders.append("Authorization", "Basic ZjBjOGNlYmQtM2U4NS00YmRhLTlkNDUtZGRkZjRhMzljOTIyOjZhOTdhMDQwLTI4YWUtNGI4My05MjBiLWMwZDQ1MWNmZGVlMA==")
            myHeaders.append("Content-Type", "application/json")
            var body = JSON.stringify({
                "grant_type": "client_credentials"
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body
            }
            fetch("https://portoapicloud.portoseguro.com.br/oauth/v2/access-token", requestOptions)
                .then(response => response.json())
                .then(result => this.atualizarDadosOrcamento(result.access_token))
                .catch(error => this.errogerado(error))
        },
        atualizarDadosOrcamento(token){
            var myHeaders2 = new Headers()
            myHeaders2.append("Accept", "application/json")
            myHeaders2.append("Authorization", "Bearer "+ token)
            myHeaders2.append("Content-Type", "application/json")
            var requestOptions2 = {
                method: 'PUT',
                headers: myHeaders2,
                body: this.dadosorcamentoatualiza
            }
            fetch('https://portoapicloud.portoseguro.com.br/fianca-locaticia/emissao/aluguel-essencial/v1/orcamentos/'+this.dadosanalise.orcamento+'/37P60J/recalculo', requestOptions2)
                .then(response => response.json())
                .then(result => this.reabrirpaginaorcamento(result))
                .catch(error => this.errogerado(error))
        },
        reabrirpaginaorcamento(dados){
            this.dadosanalise = dados
            this.dots = true
            this.acesso = '2'
        },
        formDataAtualizar(){
            this.acesso = '7'
        },
        whatsapplink(nomeseguro){
            window.location.href = 'https://api.whatsapp.com/send?phone=551145700404&text=Olá tenho uma análise aprovada n° ' + this.dadosanalise.orcamento + ', do Plano ' + nomeseguro + ', gostaria de mais informações.'
        },
        whatsapplinkerro(){
            window.location.href = 'https://api.whatsapp.com/send?phone=551145700404&text=Olá gostaria de fazer uma nova análise. Preciso de ajuda no cadastro das informações.'
        },
        whatsapplinkapolice(){
            window.location.href = 'https://api.whatsapp.com/send?phone=551145700404&text=Apólice de Seguros gerado com sucesso. ' + this.dadosapolice.apolice
        },
        continuarOnline(id){
            this.indexcod = id
            this.acesso = '2'
        },
        gerarpdfapolicelink(){
            this.acesso = '6'
            this.apolicecodigo = this.dadosapolice.apolice
            this.endosso = this.dadosapolice.endosso
        },
        validarCPF() {
            var soma = 0;
            var i;
            var resto;
            var inputCPF = this.cpforcamento.replace(/-/g, "").replace(/\./g, "")

            if (inputCPF == "00000000000" || inputCPF == "11111111111" || inputCPF == "22222222222" || inputCPF == "33333333333" ||inputCPF == "44444444444" || inputCPF == "55555555555" || inputCPF == "66666666666" || inputCPF == "77777777777" || inputCPF == "88888888888" || inputCPF == "99999999999"  ) return false;
            for (i = 1; i <= 9; i++)
                soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
            resto = (soma * 10) % 11;

            if (resto == 10 || resto == 11) resto = 0;
            if (resto != parseInt(inputCPF.substring(9, 10))) return false;

            soma = 0;
            for (i = 1; i <= 10; i++)
                soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
            resto = (soma * 10) % 11;

            if (resto == 10 || resto == 11) resto = 0;
            if (resto != parseInt(inputCPF.substring(10, 11))) return false;
            return true;
        },
        getAge() {
            const today = new Date();
            const birthDate = new Date(this.dtnasorcamento.split('/').reverse().join('-'));
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            
            return age;
        },
        verificarPeriodoData(){
            var dataInicio = new Date(this.iniciocontrato);
            var dataFim = new Date(this.fimcontrato);
            var diffMilissegundos = dataFim - dataInicio;
            var diffSegundos = diffMilissegundos / 1000;
            var diffMinutos = diffSegundos / 60;
            var diffHoras = diffMinutos / 60;
            var diffDias = diffHoras / 24;
            var diffMeses = diffDias / 30;
            if(parseInt(diffMeses) >= 6 && parseInt(diffMeses) <= 60){
                return true
            } else{
                return false
            }
        }
    },
    components: { FormulateInput }
}
</script>

<style>

    body{
        background-color: #fff;
        font-family: 'Poppins', sans-serif;
    }

    input{
        background-color: #fff !important;
        border-radius: 8px !important;
        border: 1px solid rgba(0, 0, 0, 0.516) !important;
        padding: 24px !important;
    }

    select{
        background-color: #fff !important;
        padding: 16px !important;
        border-radius: 8px !important;
        color: #000 !important;
    }

    .title-form{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 16px;
        text-align: center;
    }

    .subtitle-form{
        text-align: center;
        font-size: 12px;
        color: #000;
        font-weight: 400;
        margin-bottom: 32px;
    }

    .title-info{
        font-size: 18px;
        color: #000;
        font-weight: 800;
        text-transform: uppercase;
    }

    .subtitle-info{
        font-size: 12px;
        color: #000;
        font-weight: 400;
    }

    .title-info-p{
        margin-top: 32px;
        padding: 4px;
        background-color: #fff;
        text-align: center;
    }

    .text-formpag{
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        padding-bottom: 16px;
    }

    .div-formpag,
    .div-cobpag{
        width: 100%;
    }

    .div-cobpag p {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        padding-bottom: 16px;
    }

    .title-seguro{
        background-color: #D3D3D3;
        margin: 8px 0;
        padding: 16px;
        border-radius: 8px;
    }

    hr {
        margin: 0px 0 16px 0;
        width: 100%;
        background-color: #000000b6;
        height: 1px;
        border: none;
    }

    .containerForm {
        position: relative;
        width: 380px;
        max-width: 380px;
        min-height: 70vh;
        margin: 16px auto;
        padding: 32px 16px;
    }

    .containerForm2{
        position: relative;
        width: 80vw;
        max-width: 80vw;
        min-height: 70vh;
        margin: 16px auto;
        padding: 32px 16px;
    }

    .formulate-input .formulate-input-element {
        max-width: 100% !important;
    }

    .btn-locatario{
        background-color: #1fabe3;
        padding: 8px 32px;
        border: none;
        color: #fff;
        font-weight: 600;
    }

    .btn-locatario:hover{
        background-color: #000;
        color: #fff;
    }

    .v-alert__content{
        padding-left: 16px;
    }

    .alert-erro-1,
    .alert-erro-2{
        background-color: red !important;
    }

    table{
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
    }

    td, th{
        text-align: left;
        padding: 8px;
        border: 1px solid #ddd;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }

    tr:nth-child(even){
        background-color: #ddd;
    }

    .btn-delete{
        background-color: #fff;
        border: 0px solid #ff0000 !important;
        border-radius: 8px;
        color: #ff0000;
        border: none;
        font-size: 12px;
        font-weight: 600;
    }

    .btn-delete:hover{
        border: 0px solid #ff0000;
        color: #ff0000;
        font-weight: bold;
    }

    .btn-delete i{
        color: #ff0000 !important;
    }

    @media (min-width: 420px) {
        .triple-wide,
        .double-wide{
            display: flex;
        }

        .triple-wide{
            align-items: center;
        }

        .triple-wide .formulate-input,
        .btn-locatario{
            flex-grow: 1;
            width: 33%;
            margin: 0px 0px 16px 0px;
        }

        .double-wide .formulate-input{
            flex-grow: 1;
            width: 50%;
            margin-right: 8px;
        }

        .btn-locatario{
            height: 40px;
            border-radius: 4px;
        }

    }

    @media (max-width: 420px){
        .containerForm2,
        .containerForm{
            width: 95vw;
            max-width: 95vw;
        }
    }

    .table-cobertura{
        margin-bottom: 32px;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .btnenviar button{
        width: 100% !important;
        padding: 16px 0px !important;
        font-size: 18px;
        justify-content: center;
    }

    .btn-locatario, .btn-delete{
        cursor: pointer;
    }

    .condicoespagamento,
    .coberturas, .beneficios{
        display: flex;
        position: relative;
        justify-content: flex-start;
        max-width: 100%;
        flex-flow: row wrap;
    }

    .condicoespagamento-div{
        flex-grow: 1;
        max-width: 33%;
        margin: 8px;
        border: 1px solid #cecece;
        border-radius: 8px;
        padding: 16px 8px;
    }

    .coberturas-div, .beneficios-div{
        flex-wrap: nowrap;
        flex-direction: row;
        flex-grow: 1;
        max-width: 25%;
        margin: 8px;
        border: 1px solid #cecece;
        border-radius: 8px;
        padding: 16px 8px;
    }

    .condicoespagamento-div:hover,
    .coberturas-div:hover,
    .beneficios-div:hover{
        box-shadow: 2px 2px 0px #e9e9e9;
    }

    .btn-selecionarpag{
        width: 100%;
        margin-top: 16px;
        padding: 8px;
        background-color: #e9e9e9;
        color: #181818;
        font-size: 16px;
        font-weight: 600;
        border: 1px solid #cecece;
        border-radius: 4px;
        cursor: pointer;
    }

    .btn-selecionarpag:hover{
        background-color: #1fabe3;
    }

    .ofertas-div{
        border: 1px solid #cecece;
        border-radius: 8px;
        padding: 16px 8px;
        margin: 16px 0px;
    }

    .ofertas-div>h2{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .formaspagamento>h2{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .dots {
        width: 5.8px;
        height: 5.8px;
        margin: 24px auto;
        border-radius: 50%;
        color: #1FAAE3;
        box-shadow: 27.4px 0 0 10.1px, 54.8px 0 0 4.3px, 82.19999999999999px 0 0 0;
        transform: translateX(-54.8px);
        animation: dots-ijr34dlg 0.7s infinite alternate linear;
        }
        .dots2 {
        width: 1.1px;
        height: 1.1px;
        margin: 24px 0px 24px 40px;
        border-radius: 50%;
        color: #1FAAE3;
        box-shadow: 27.4px 0 0 10.1px, 54.8px 0 0 4.3px, 82.19999999999999px 0 0 0;
        transform: translateX(-54.8px);
        animation: dots-ijr34dlg 0.8s infinite alternate linear;
        }

        @keyframes dots-ijr34dlg {
        50% {
            box-shadow: 27.4px 0 0 4.3px, 54.8px 0 0 10.1px, 82.19999999999999px 0 0 4.3px;
        }

        100% {
            box-shadow: 27.4px 0 0 0, 54.8px 0 0 4.3px, 82.19999999999999px 0 0 10.1px;
        }
        }
    
        .navbar{
            position: relative;
            display: flex;
            justify-content: center;
            width: 100vw;
            background-color: #fff;
            flex-flow: row wrap;
        }

        .img-div{
            max-width: 300px;
            position: relative;
        }

        .navbar img{
            max-width: 200px;
        }

        .menutop{
            position: relative;
            width: 50%;
            display: flex;
            align-items: center;
        }

        .menu{
            display: flex;
            justify-content: flex-start;
        }

        .menu li{
            list-style-type: none;
            padding: 0px 8px;
            cursor: pointer;
        }
        .menu li a{
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
        }

        .menu li a:hover{
            color: #ececec;
        }

        .div-btn-pdf{
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 64px 0;
        }

        #pdforcamento a,
        #pdfapolicelink a{
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;
            background-color: #00bc00;
            border: 1px solid #00bc00;
            border-radius: 8px;
            padding: 16px;
        }
        #pdfapolice a{
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;
            margin: 16px auto;
            background-color: #1FAAE3;
            padding: 8px 32px;
        }

        #pdforcamento a:hover,
        #pdfapolice a:hover,
        #pdfapolicelink a:hover{
            background-color: #fff;
        }

        .div-btn-fin{
            width: 100%;
            margin-top: 16px;
        }

        .btn-fin-wa,
        .btn-fin-on{
            width: 100%;
            padding: 8px;
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
        }

        .btn-fin-wa i{
            color: #fff !important;
            padding-right: 8px;
        }

        .btn-fin-wa{
            background-color: #00bc00;
            border: 1px solid #00bc00;
            color: #fff !important;
        }

        .btn-fin-wa:hover{
            background-color: #008700;
            color: #fff;
        }

        .btn-fin-on{
            background-color: #000;
            border: 1px solid #000;
            color: #fff;
        }

        .btn-fin-on:hover{
            background-color: #424242;
            color: #fff;
        }

        .cardtitle-ofertas span{
            font-size: 14px;
        }

        .div-heading{
            width: 100%;
            text-align: center;
        }

        .div-heading h5{
            font-size: 16px;
        }

        .btn-action button{
            background-color: #000 !important;
            border: 1px solid #000 !important;
            width: 100% !important;
        }

        .btn-action{
            margin: 0 auto;
            max-width: 50% !important;
        }

        .btn-action button span{
            margin: 0 auto !important;
        }

        .formulate-input-error{
            font-weight: 600 !important;
        }

        .btn-action button:hover{
            background-color: #fff !important;
            color: #000 !important;
        }

        .vcard-ofertas{
            border-radius: 8px;
            border: 1px solid #a9a9a9 !important;
        }

        .btn-finalizar-action button{
            background-color: #1FAAE3 !important;
            border: 1px solid #1FAAE3 !important;
            color: #fff !important;
            font-weight: 600 !important;
            text-transform: uppercase;
            width: 100% !important;
            justify-content: center;
            padding: 16px 0 !important;
        }

        .btn-finalizar-action button:hover{
            background-color: #fff !important;
            color: #1FAAE3 !important;
        }

        .btn-finalizar-action2{
            background-color: #1FAAE3 !important;
            border: 1px solid #1FAAE3 !important;
            color: #fff !important;
            font-weight: 600 !important;
            text-transform: uppercase;
            width: 100% !important;
            justify-content: center;
            padding: 8px 0 !important;
        }

        .btn-finalizar-action2:hover{
            background-color: #fff !important;
            color: #1FAAE3 !important;
        }

        .btn-cancel-action{
            background-color: #000 !important;
            border: 1px solid #000 !important;
            color: #fff !important;
            font-weight: 600 !important;
            text-transform: uppercase;
            padding: 16px;
            margin-top: 32px;
        }

        .btn-cancel-action:hover{
            background-color: #fff !important;
            color: #000 !important;
        }

        .btn-cancel-action2{
            background-color: #fff !important;
            border: 1px solid #000 !important;
            color: #000 !important;
            font-weight: 600 !important;
            text-transform: uppercase;
        }

        .btn-cancel-action2:hover{
            background-color: #000 !important;
            color: #fff !important;
        }

        .vcard-main{
            padding: 16px 16px 0px 16px !important;
        }
</style>