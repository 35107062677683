<template>
  <div>
    <FormAnalise></FormAnalise>
    <FooterMain></FooterMain>
  </div>
</template>

<script>
import FormAnalise from './components/FormAnalise';
import FooterMain from './components/FooterMain';

export default {
  name: 'App',

  components: {
    FormAnalise,
    FooterMain
  },

  data: () => ({
    //
  }),
};
</script>
