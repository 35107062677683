import Vue from 'vue'
import App from './App.vue'
import './assets/formulate.css'

import VueFormulate from '@braid/vue-formulate'
import VueFormulateExtended from 'vue-formulate-extended'
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);
Vue.use(VueFormulate, {
  plugins: [
    VueFormulateExtended({
      features: {
        textMask: true
      },
    }),
  ],
})



Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
